import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
// only used to check and request permissions
import { Camera } from '@capacitor/camera'
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings'

interface Settings {
  pushStatus: 'initial' | 'accepted' | 'rejected'
  reminderStatus: 'initial' | 'accepted' | 'rejected'
  mediasAndPhotoStatus: 'initial' | 'accepted' | 'rejected'
}

const SETTINGS_KEY = 'settings'
const settings = ref<Settings>({
  pushStatus: 'initial',
  reminderStatus: 'initial',
  mediasAndPhotoStatus: 'initial',
})

async function loadSettings() {
  if (!Capacitor.isNativePlatform()) return
  if (
    settings.value.pushStatus !== 'initial' ||
    settings.value.reminderStatus !== 'initial'
  )
    return

  const { value } = await Preferences.get({ key: SETTINGS_KEY })
  if (value) {
    settings.value = JSON.parse(value)
    // Check if user manually accepted push notifications
    if (
      settings.value.pushStatus === 'rejected' &&
      settings.value.reminderStatus === 'rejected' &&
      (await usePush().permissionIsGranted())
    ) {
      settings.value.pushStatus = 'accepted'
      settings.value.reminderStatus = 'initial'
    }
  }
}

watch(
  settings,
  async (newSettings) => {
    await Preferences.set({
      key: SETTINGS_KEY,
      value: JSON.stringify(newSettings),
    })
  },
  { deep: true },
)

loadSettings()

export default function useSettings() {
  return {
    settings: readonly(settings),
    initPush: async () => {
      const isPushAllowed = await usePush().registerNotifications()
      if (isPushAllowed) settings.value.pushStatus = 'accepted'
      else {
        settings.value.pushStatus = 'rejected'
        settings.value.reminderStatus = 'rejected'
      }
    },
    proposeReminder: async () => {
      const localNotifications = useLocalNotifications()
      if (settings.value.reminderStatus === 'rejected') return
      if (settings.value.reminderStatus === 'accepted')
        return await localNotifications.init()
      const toast = useToast()
      toast.add({
        title: 'Rappel quotidien',
        description:
          'Souhaites-tu recevoir un rappel quotidien pour faire ta prediction ?',
        timeout: 0,
        actions: [
          {
            label: 'Oui',
            click: async () => {
              settings.value.reminderStatus = 'accepted'
              await localNotifications.init()
            },
          },
          {
            label: 'Non',
            click: async () => {
              settings.value.reminderStatus = 'rejected'
              await localNotifications.deleteAll()
            },
          },
        ],
      })
    },
    initMedias: async () => {
      if (!Capacitor.isNativePlatform()) return true
      const { photos, camera } = await Camera.requestPermissions()

      if (photos === 'granted' && camera === 'granted') {
        settings.value.mediasAndPhotoStatus = 'accepted'
        return true
      }
      settings.value.mediasAndPhotoStatus = 'rejected'
      const toast = useToast()
      toast.add({
        title: 'Permission refusée',
        description:
          'Almana.cc doit pouvoir prendre des photos et accéder à tes albums afin d\'enregistrer tes predictions. Veuillez changer vos paramètres puis relancer l\'application.',
        timeout: 0,
        actions: [
          {
            label: 'Ouvrir les paramètres',
            click: async () => {
              await NativeSettings.open({
                optionAndroid: AndroidSettings.ApplicationDetails,
                optionIOS: IOSSettings.App,
              })
            },
          },
        ],
      })
      return false
    },
  }
}
