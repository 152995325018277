import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'
import { CapacitorUpdater } from '@capgo/capacitor-updater'

export default defineNuxtPlugin(async (nuxtApp) => {
  const isNative = Capacitor.isNativePlatform()
  const appFetch = $fetch.create({
    baseURL: nuxtApp.$config.public.baseUrlForMobile,
    referrerPolicy: 'unsafe-url', // potentially ca dégage
    async onRequest({ options }) {
      if (isNative) {
        const { bundle } = await CapacitorUpdater.current()
        const info = await App.getInfo()
        const version = bundle.version === 'builtin' ? info.version : bundle.version
        
        options.headers = {
          ...options.headers,
          'X-App-Version': version,
          'X-App-Device': (await Device.getId()).identifier,
        }
      } else if (import.meta.dev) {
        options.headers = {
          ...options.headers,
          'X-App-Version': 'dev',
          'X-App-Device': (await Device.getId()).identifier,
        }
      }
    },
    onResponse({ options }) {
      // console.log('LOVA onResponse', JSON.stringify(options))
      // set Manas in Preferences?
    },
  })
  return {
    provide: { appFetch },
  }
})
