import { default as _91_46_46_46slug_93TVP4E0IjapMeta } from "/home/runner/work/web/web/pages/[...slug].vue?macro=true";
import { default as add_45themeIsxaOnpiWjMeta } from "/home/runner/work/web/web/pages/admin/add-theme.vue?macro=true";
import { default as generate_45imagefQBAweSUeWMeta } from "/home/runner/work/web/web/pages/admin/generate-image.vue?macro=true";
import { default as index5VppKuXQNFMeta } from "/home/runner/work/web/web/pages/admin/index.vue?macro=true";
import { default as manasbQ6kC15JSWMeta } from "/home/runner/work/web/web/pages/admin/manas.vue?macro=true";
import { default as adminLPMaOF9WPPMeta } from "/home/runner/work/web/web/pages/admin.vue?macro=true";
import { default as home_45devVDXinNGHsVMeta } from "/home/runner/work/web/web/pages/home-dev.vue?macro=true";
import { default as index8Wx8VjfYI0Meta } from "/home/runner/work/web/web/pages/index.vue?macro=true";
import { default as logintl35qSNwFpMeta } from "/home/runner/work/web/web/pages/login.vue?macro=true";
import { default as calendrierFlTvJ5jYctMeta } from "/home/runner/work/web/web/pages/mobile/calendrier.vue?macro=true";
import { default as faqYKBEn4HaNsMeta } from "/home/runner/work/web/web/pages/mobile/faq.vue?macro=true";
import { default as indexAdJNI1F0F0Meta } from "/home/runner/work/web/web/pages/mobile/index.vue?macro=true";
import { default as camerazedANntBQjMeta } from "/home/runner/work/web/web/pages/mobile/theme/[date]/camera.vue?macro=true";
import { default as indexPx2VG4kXucMeta } from "/home/runner/work/web/web/pages/mobile/theme/[date]/index.vue?macro=true";
import { default as userm1Fn0aE5AZMeta } from "/home/runner/work/web/web/pages/mobile/user.vue?macro=true";
import { default as mobiletSxytiCFiOMeta } from "/home/runner/work/web/web/pages/mobile.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93TVP4E0IjapMeta || {},
    component: () => import("/home/runner/work/web/web/pages/[...slug].vue")
  },
  {
    name: adminLPMaOF9WPPMeta?.name,
    path: "/admin",
    meta: adminLPMaOF9WPPMeta || {},
    component: () => import("/home/runner/work/web/web/pages/admin.vue"),
    children: [
  {
    name: "admin-add-theme",
    path: "add-theme",
    component: () => import("/home/runner/work/web/web/pages/admin/add-theme.vue")
  },
  {
    name: "admin-generate-image",
    path: "generate-image",
    component: () => import("/home/runner/work/web/web/pages/admin/generate-image.vue")
  },
  {
    name: "admin",
    path: "",
    component: () => import("/home/runner/work/web/web/pages/admin/index.vue")
  },
  {
    name: "admin-manas",
    path: "manas",
    component: () => import("/home/runner/work/web/web/pages/admin/manas.vue")
  }
]
  },
  {
    name: "home-dev",
    path: "/home-dev",
    meta: home_45devVDXinNGHsVMeta || {},
    component: () => import("/home/runner/work/web/web/pages/home-dev.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index8Wx8VjfYI0Meta || {},
    component: () => import("/home/runner/work/web/web/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/web/web/pages/login.vue")
  },
  {
    name: mobiletSxytiCFiOMeta?.name,
    path: "/mobile",
    meta: mobiletSxytiCFiOMeta || {},
    component: () => import("/home/runner/work/web/web/pages/mobile.vue"),
    children: [
  {
    name: "mobile-calendrier",
    path: "calendrier",
    component: () => import("/home/runner/work/web/web/pages/mobile/calendrier.vue")
  },
  {
    name: "mobile-faq",
    path: "faq",
    component: () => import("/home/runner/work/web/web/pages/mobile/faq.vue")
  },
  {
    name: "mobile",
    path: "",
    meta: indexAdJNI1F0F0Meta || {},
    component: () => import("/home/runner/work/web/web/pages/mobile/index.vue")
  },
  {
    name: "mobile-theme-date-camera",
    path: "theme/:date()/camera",
    meta: camerazedANntBQjMeta || {},
    component: () => import("/home/runner/work/web/web/pages/mobile/theme/[date]/camera.vue")
  },
  {
    name: "mobile-theme-date",
    path: "theme/:date()",
    meta: indexPx2VG4kXucMeta || {},
    component: () => import("/home/runner/work/web/web/pages/mobile/theme/[date]/index.vue")
  },
  {
    name: "mobile-user",
    path: "user",
    component: () => import("/home/runner/work/web/web/pages/mobile/user.vue")
  }
]
  }
]