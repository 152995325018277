import { Media } from '@capacitor-community/media'
import { Filesystem } from '@capacitor/filesystem'
import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import webFunctions from './fileSystem.web'

export interface Image {
  identifier?: string // apple filename to map with ours in path
  name: string
  path: string
  src: string
  thumbnail: string
}

const isIOS = Capacitor.getPlatform() === 'ios'
const ALBUM_NAME = 'Almana.cc'
const MEDIA_KEY = 'photos4'

const getAlbumIdentifier = async () => {
  const { albums } = await Media.getAlbums()
  const album = albums.find((a) => a.name === ALBUM_NAME)
  return album?.identifier
}
const ensureAlbum = async () => {
  let id = await getAlbumIdentifier()
  if (!id) {
    await Media.createAlbum({ name: ALBUM_NAME })
    id = await getAlbumIdentifier()
  }
  return id
}

export default async () => {
  if (!Capacitor.isNativePlatform()) return webFunctions

  const albumIdentifier = await ensureAlbum()
  const { value } = await Preferences.get({ key: MEDIA_KEY })
  let storedMedias = (value ? JSON.parse(value) : []) as Image[] // Only use with ios for the moment

  /**
   * Fetches images from the folder.
   * @returns {Image[]} - Array of images with uri: fileSystem string, src and thumbnail to be use directly in <img> tag and path = name.
   */
  const fetchImagesFromFolder = async (): Promise<Image[]> => {
    if (isIOS) {
      // Each time we have to create a new SRC
      const uptdate = await Promise.all(
        storedMedias.map(async (media) => {
          const newbie = await Media.getMediaByIdentifier({
            identifier: media.identifier,
          }).catch((_) => null)
          if (!newbie) return {} as Image
          return {
            ...media,
            path: newbie.path,
            src: Capacitor.convertFileSrc(newbie.path),
          }
        }),
      )
      return uptdate.filter((m) => !!m.identifier) // TODO check that all stored media exists
    } else {
      const result = await Filesystem.readdir({ path: albumIdentifier })
      return await Promise.all(
        result.files.map(async (file) => {
          const fileObject = await Filesystem.readFile({ path: file.uri })
          const base64Image = 'data:image/jpeg;base64,' + fileObject.data
          return {
            name: file.name,
            path: file.uri,
            src: Capacitor.convertFileSrc(file.uri),
            thumbnail: base64Image,
          }
        }),
      )
    }
  }

  /**
   * Saves an image to the folder.
   * @param {string} imageURL - URL of the image to be saved.
   * @param {string} date - Date associated with the image.
   */
  const saveImageToFolder = async (
    imageURL: string,
    date: string,
    id: string,
  ): Promise<Image> => {
    let media = {}

    if (isIOS) {
      if (imageURL.startsWith('data:video/mp4')) {
        await Media.saveVideo({
          path: imageURL,
          albumIdentifier,
        })
      } else {
        await Media.savePhoto({
          path: imageURL,
          albumIdentifier,
        })
      }
      const { medias } = await Media.getMedias({
        thumbnailWidth: 320,
        thumbnailQuality: 80,
        types: 'all',
        albumIdentifier,
      })
      const sortedMedias = medias.sort(
        (a, b) =>
          new Date(b.creationDate).getTime() -
          new Date(a.creationDate).getTime(),
      )
      const { path, identifier } = await Media.getMediaByIdentifier({
        identifier: sortedMedias[0].identifier,
      })
      const isVideo = !!sortedMedias[0].duration

      media = {
        identifier,
        name: `${date}_${id}.${isVideo ? 'mp4' : 'png'}`,
        path,
        thumbnail: isVideo
          ? 'data:video/mp4;base64,' + sortedMedias[0].data
          : 'data:image/jpeg;base64,' + sortedMedias[0].data,
        src: Capacitor.convertFileSrc(path),
      }
      storedMedias.push(media)
      await Preferences.set({
        key: MEDIA_KEY,
        value: JSON.stringify(storedMedias),
      })
    } else {
      const { filePath } = await Media.savePhoto({
        path: imageURL,
        albumIdentifier,
        fileName: `${date}_${id}`, // not working in ios
      })
      const path = 'file://' + filePath
      const src = Capacitor.convertFileSrc(path)
      media = {
        name: `${date}_${id}.png`,
        path,
        src,
        thumbnail: src,
      }
    }
    return media
  }

  /**
   * Deletes an image from the folder.
   * @param {Image} image - Image object to be deleted
   */
  const deleteImageFromFolder = async (image: Image): Promise<void> => {
    if (isIOS) {
      await Filesystem.deleteFile({ path: image.path }) // Not really delete the image.
      storedMedias = storedMedias.filter(
        (media) => media.identifier !== image.identifier,
      )
      await Preferences.set({
        key: MEDIA_KEY,
        value: JSON.stringify(storedMedias),
      })
    } else await Filesystem.deleteFile({ path: image.path })
  }

  return { deleteImageFromFolder, saveImageToFolder, fetchImagesFromFolder }
}
